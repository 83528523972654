<template>

  <div class="row">
    <div class="col-12 card">
      <div class="row justify-content-space-between top-24">
        <h4 class="col-3 card-title">Добавить запись в справочник</h4>
        <div class="col-9 align-self-end right">
          <router-link to="/guides/1"><button class="col-3 btn-secondary">Отменить</button></router-link>
          <button class="col-3 btn-primary"><span class="icon-Plus"></span>Сохранить</button>
        </div>
      </div>
      <div class="row">
        <div class="col-12 top-24">
          <div class="text-field">
            <label class="text-field__label" for="search">Наименование автомобиля</label>
            <div class="text-field__icon">
              <input class="text-field__input" type="search" name="search" id="search" placeholder="Введите имя" value="">
            </div>
          </div>
        </div>
        <div class="col-12 top-24">
          <div class="text-field">
            <label class="text-field__label" for="search">Тип атомобиля</label>
            <div class="text-field__icon">
                <div class="select">
                  <select id="standard-select">
                    <option value="Option 1">Камаз</option>
                    <option value="Option 2">Не Камаз</option>
                  </select>

                </div>
            </div>
          </div>
        </div>
        <div class="col-12 top-24">
          <div class="text-field">
            <label class="text-field__label" for="search">Государственный регистрационный номер</label>
            <div class="text-field__icon">
              <input class="text-field__input" type="search" name="search" id="search" placeholder="Введите госномер" value="">
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

</script>
